/* eslint-disable no-script-url */
import React from "react";

const Footer: React.FC = (props) => {
  return (
    <footer className="py-4 bg-light mt-auto">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">Copyright &copy; Grameen Milk 2021</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
