import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Loading from "./components/loader";

import "bootstrap/scss/bootstrap.scss";
import "./assets/css/admin.scss";
import RouteManagerLayout from "./layouts/routeManager";
import { useAppSelector } from "./store/hooks";
import getFirstRoute from "./utils/getFirstRoute";
import RouteAdminSalesCustomerProductwiseDetails from "./containers/admin/routeAdminSalesCustomerProductwise";

const AdminLayout = lazy(() => import("./layouts/admin"));
const Login = lazy(() => import("./containers/public/login"));

const Settings = lazy(() => import("./containers/admin/settings"));
const AdminRoutes = lazy(() => import("./containers/admin/routes/list"));
const Customers = lazy(() => import("./containers/admin/customers/list"));
const Products = lazy(() => import("./containers/admin/products/list"));
const CustomerCreateEdit = lazy(
  () => import("./containers/admin/customers/createEdit")
);
const ProductCreateEdit = lazy(
  () => import("./containers/admin/products/createEdit")
);
const RouteCreateEdit = lazy(
  () => import("./containers/admin/routes/createEdit")
);
const CustomerView = lazy(() => import("./containers/admin/customers/view"));
const RouteView = lazy(() => import("./containers/admin/routes/view"));
const CollectionReport = lazy(
  () => import("./containers/admin/collectionReport")
);
const CustomerReport = lazy(() => import("./containers/admin/customerReport"));
const CustomerSalesReport = lazy(
  () => import("./containers/admin/routes/customerSalesReport")
);
const ProductView = lazy(() => import("./containers/admin/products/view"));
const SummaryReport = lazy(() => import("./containers/admin/productSummary"));
const RoutesSummary = lazy(() => import("./containers/admin/routeSummary"));

const UserCreate = lazy(() => import("./containers/admin/users/createEdit"));
const UserList = lazy(() => import("./containers/admin/users/list"));
const UserView = lazy(() => import("./containers/admin/users/view"));
const Orders = lazy(() => import("./containers/admin/orders/list"));
const ItemsReport = lazy(() => import("./containers/admin/itemwiseReport"));
const RouteSalesReport = lazy(
  () => import("./containers/admin/routeSalesReport")
);
const CreateEditRouteAdmins = lazy(
  () => import("./containers/admin/routeAdmins/createEdit")
);
const RouteAdmins = lazy(() => import("./containers/admin/routeAdmins/list"));
const RouteAdminDetails = lazy(
  () => import("./containers/admin/routeAdmins/view")
);
const PettyCashReport = lazy(
  () => import("./containers/admin/pettyCashReport")
);

const RouteAdminRoutes = lazy(
  () => import("./containers/routeManager/routes/list")
);
const RouteAdminRouteView = lazy(
  () => import("./containers/routeManager/routes/view")
);
const RouteAdminUsersList = lazy(
  () => import("./containers/routeManager/customers/list")
);
const RouteAdminCustomer = lazy(
  () => import("./containers/routeManager/customers/view")
);
const RouteAdminCustomersReport = lazy(
  () => import("./containers/routeManager/customerReport")
);
const RouteAdminCustomersSalesReport = lazy(
  () => import("./containers/routeManager/routes/customerSalesReport")
);
const RouteAdminCollectionReport = lazy(
  () => import("./containers/routeManager/collectionReport")
);
const RouteAdminRouteAdmins = lazy(
  () => import("./containers/routeManager/routeAdmins/list")
);
const RouteAdminRouteAdminDetails = lazy(
  () => import("./containers/routeManager/routeAdmins/view")
);
const RouteAdminSalesSummaryReport = lazy(
  () => import("./containers/admin/routeAdminSalesSummaryReport")
);
const RouteAdminSalesSummaryDetails = lazy(
  () => import("./containers/admin/routeAdminSalesSummaryDetails")
);

const App = () => {
  const { userPermissions, role } = useAppSelector((state) => state.auth);

  const renderElement = (
    role: "admin" | "route_manager" | "super_admin" | undefined,
    Component: React.FC,
    permission?: boolean
  ) => {
    if (role === "super_admin" || (role === "admin" && permission)) {
      return <Component />;
    } else {
      return <Navigate to={`/admin/${getFirstRoute(userPermissions)}`} />;
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={"/admin"} element={<AdminLayout />}>
          {[
            {
              path: "customers/create",
              permission: "addCustomer",
            },
            {
              path: "customers/edit/:id",
              permission: "updateCustomer",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      CustomerCreateEdit,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            {
              path: "products/create",
              permission: "addProduct",
            },
            {
              path: "products/edit/:id",
              permission: "updateProduct",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      ProductCreateEdit,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"products/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  ProductView,
                  userPermissions.includes("viewProduct")
                )}
              </Suspense>
            }
          />
          {[
            {
              path: "routes/create",
              permission: "addRoute",
            },
            {
              path: "routes/edit/:id",
              permission: "updateRoute",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      RouteCreateEdit,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            {
              path: "route-admins/create",
              permission: "addRouteEmployee",
            },
            {
              path: "route-admins/edit/:id",
              permission: "updateRouteEmployee",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      CreateEditRouteAdmins,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "users/create", permission: "addUser" },
            { path: "users/edit/:id", permission: "updateUser" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      UserCreate,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "users", permission: "userList" },
            { path: "users/:page", permission: "userList" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      UserList,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"customers/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  CustomerView,
                  userPermissions.includes("viewCustomer")
                )}
              </Suspense>
            }
          />
          <Route
            path={"routes/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteView,
                  userPermissions.includes("viewRoute")
                )}
              </Suspense>
            }
          />
          <Route
            path={"route-admins/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteAdminDetails,
                  userPermissions.includes("employeeRouteList")
                )}
              </Suspense>
            }
          />
          <Route
            path={"users/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  UserView,
                  userPermissions.includes("viewUser")
                )}
              </Suspense>
            }
          />
          {[
            { path: "collection-report", permission: "routeCollection" },
            { path: "collection-report/:page", permission: "routeCollection" },
            {
              path: "routes/:id/collection-report",
              permission: "routeCollection",
            },
            {
              path: "routes/:id/collection-report/:page",
              permission: "routeCollection",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      CollectionReport,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "customers-report", permission: "salesReport" },
            { path: "customers-report/:page", permission: "salesReport" },
            { path: "routes/:id/customers-report", permission: "salesReport" },
            {
              path: "routes/:id/customers-report/:page",
              permission: "salesReport",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      CustomerReport,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"routes/:id/customers-sales-report/:customerId"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  CustomerSalesReport,
                  userPermissions.includes("supplyDetails")
                )}
              </Suspense>
            }
          />
          {[
            { path: "petty-cash-report", permission: "pettyCashReport" },
            { path: "petty-cash-report/:page", permission: "pettyCashReport" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      PettyCashReport,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "product-summary", permission: "routeSupplyDetails" },
            {
              path: "routes/:id/product-summary-report",
              permission: "routeSupplyDetails",
            },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      SummaryReport,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"routes-summary"}
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RoutesSummary,
                  userPermissions.includes("allSupplyDetails")
                )}
              </Suspense>
            }
          />
          {[
            { path: "routes", permission: "routeListData" },
            { path: "routes/:page", permission: "routeListData" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      AdminRoutes,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "route-admins", permission: "routeManagersList" },
            { path: "route-admins/:page", permission: "routeManagersList" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      RouteAdmins,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "products", permission: "productList" },
            { path: "products/:page", permission: "productList" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      Products,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "customers", permission: "customerList" },
            { path: "customers/:page", permission: "customerList" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.permission}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      Customers,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          {[
            { path: "orders", permission: "orderList" },
            { path: "orders/:page", permission: "orderList" },
          ].map((item) => {
            return (
              <Route
                path={item.path}
                key={item.path}
                element={
                  <Suspense fallback={<Loading />}>
                    {renderElement(
                      role,
                      Orders,
                      userPermissions.includes(item.permission)
                    )}
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path="items-report"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  ItemsReport,
                  userPermissions.includes("itemConsolidatedReport")
                )}
              </Suspense>
            }
          />
          <Route
            path="route-sales-report"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteSalesReport,
                  userPermissions.includes("routeSalesConsolidateReport")
                )}
              </Suspense>
            }
          />
          <Route
            path="route-admin-sales-summary-details/:user_id/page/:page"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteAdminSalesSummaryDetails,
                  userPermissions.includes("routeAdminSalesSummaryReport")
                )}
              </Suspense>
            }
          />
          <Route
            path="route-admin-sales-summary-details/:user_id/customer-product-summary/:customer_id/page/:page"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteAdminSalesCustomerProductwiseDetails,
                  userPermissions.includes("routeAdminSalesSummaryReport")
                )}
              </Suspense>
            }
          />
          <Route
            path="route-admin-sales-summary-details/:user_id/customer/:customer_id"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteAdminSalesSummaryDetails,
                  userPermissions.includes("routeAdminSalesSummaryReport")
                )}
              </Suspense>
            }
          />
          <Route
            path="route-admin-sales-summary-report"
            element={
              <Suspense fallback={<Loading />}>
                {renderElement(
                  role,
                  RouteAdminSalesSummaryReport,
                  userPermissions.includes("routeAdminSalesSummaryReport")
                )}
              </Suspense>
            }
          />

          <Route
            path="settings"
            element={
              <Suspense fallback={<Loading />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={getFirstRoute(userPermissions)} />}
          />
          <Route
            path="*"
            element={<Navigate to={getFirstRoute(userPermissions)} />}
          />
        </Route>
        <Route
          path={"route-manager"}
          element={
            <Suspense fallback={<Loading />}>
              <RouteManagerLayout />
            </Suspense>
          }
        >
          <Route
            path={"routes/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                <RouteAdminRouteView />
              </Suspense>
            }
          />
          {["routes", "routes/:page"].map((item) => {
            return (
              <Route
                path={item}
                key={`ra${item}`}
                element={
                  <Suspense fallback={<Loading />}>
                    <RouteAdminRoutes />
                  </Suspense>
                }
              />
            );
          })}
          {["customers", "customers/:page"].map((item) => {
            return (
              <Route
                path={item}
                key={`ra${item}`}
                element={
                  <Suspense fallback={<Loading />}>
                    <RouteAdminUsersList />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"customers/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                <RouteAdminCustomer />
              </Suspense>
            }
          />
          {[
            "customers-report",
            "customers-report/:page",
            "routes/:id/customers-report",
            "routes/:id/customers-report/:page",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={`ra${item}`}
                element={
                  <Suspense fallback={<Loading />}>
                    <RouteAdminCustomersReport />
                  </Suspense>
                }
              />
            );
          })}
          {[
            "collection-report",
            "collection-report/:page",
            "routes/:id/collection-report",
            "routes/:id/collection-report/:page",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={`ra${item}`}
                element={
                  <Suspense fallback={<Loading />}>
                    <RouteAdminCollectionReport />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path={"routes/:id/customers-sales-report/:customerId"}
            element={
              <Suspense fallback={<Loading />}>
                <RouteAdminCustomersSalesReport />
              </Suspense>
            }
          />
          <Route
            path={"route-managers/view/:id"}
            element={
              <Suspense fallback={<Loading />}>
                <RouteAdminRouteAdminDetails />
              </Suspense>
            }
          />
          {["route-managers", "route-managers/:page"].map((item) => {
            return (
              <Route
                path={item}
                key={`ra${item}`}
                element={
                  <Suspense fallback={<Loading />}>
                    <RouteAdminRouteAdmins />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path="settings"
            element={
              <Suspense fallback={<Loading />}>
                <Settings />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={"routes"} />} />
        </Route>
        <Route path={"/login"} element={<Login />} />
        <Route index element={<Navigate to={"/login"} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
