import axios from "axios";
import LocalStorageService from "./localStorageService";
import API from "../config/api";
import { store } from "../store/store";
import { logout } from "../store/slices/auth";

const localStorageService = LocalStorageService.getService();

const service = axios.create({
  baseURL: "",
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers.Accept = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Device-Id"] = "";
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== API.REFRESH_TOKEN &&
      originalRequest.url !== API.LOGIN &&
      originalRequest.url !== API.RESET_PASSWORD &&
      originalRequest.url !== API.FORGOT_PASSWORD
    ) {
      originalRequest._retry = true;
      return await fetch(API.REFRESH_TOKEN, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          refreshtoken: localStorageService.getRefreshToken(),
        },
        // body: JSON.stringify({
        //   token: localStorageService.getRefreshToken(),
        // }),
      })
        .then((data: any) => {
          return data.json();
        })
        .then((res: any) => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            localStorageService.setAccessToken(res.data.access_token);
            localStorageService.setRefreshToken(res.data.refresh_token);
            // 2) Change Authorization header
            service.defaults.headers.common["Authorization"] =
              "Bearer " + res.access_token;
            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          } else {
            localStorageService.clearToken();
            store.dispatch(logout());
            window.location.href = "/login";
            return Promise.reject(error.config ? error.response.data : error);
          }
        })
        .catch((err) => {
          localStorageService.clearToken();
          store.dispatch(logout());
          window.location.href = "/login";
          return Promise.reject(error.config ? error.response.data : error);
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
