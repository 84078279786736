import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import SelectSearch from "react-select-search";

import "react-datepicker/dist/react-datepicker.css";

interface InputProps {
  id: string;
  type:
    | "text"
    | "date"
    | "select"
    | "textarea"
    | "password"
    | "file"
    | "checkbox"
    | "search-select";
  value: string | number | boolean;
  label: string;
  placeholder: string;
  options?: { value: string | number; label: string }[] | [];
  isRequired?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  styleClass?: string;
  onChange: (e: any) => void;
  ref?: any;
  hideDefault?: boolean;
  hideLabel?: boolean;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
  accept?: string;
  handleBlur?: (v: any) => any;
}

const AdminInput: React.FC<InputProps> = React.forwardRef((props, ref: any) => {
  const renderInput = (props: any) => {
    switch (props.type) {
      case "text":
        return (
          <input
            value={props.value}
            className="form-control"
            id={props.placeholder}
            type="text"
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
            required={props.isRequired ? props.isRequired : false}
            disabled={props.disabled ? props.disabled : false}
            maxLength={props.maxLength || undefined}
            minLength={props.minLength || undefined}
            pattern={props.pattern || undefined}
            ref={ref}
            onBlur={props.handleBlur}
          />
        );
      case "select":
        return (
          <select
            className="custom-select d-block w-100"
            id={props.id}
            value={props.value}
            required={props.isRequired}
            disabled={props.disabled ? props.disabled : false}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.handleBlur}
          >
            {props.hideDefault ? null : (
              <option value="" key={`${props.id}-default`}>
                {props.placeholder}
              </option>
            )}
            {props.options && props.options.length > 0
              ? props.options.map((option: any, i: number) => {
                  return (
                    <option value={option.value} key={`${option.label}-${i}`}>
                      {option.label}
                    </option>
                  );
                })
              : null}
          </select>
        );
      case "search-select":
        return (
          <SelectSearch
            search
            options={props.options}
            value={props.value}
            // name="Route"
            placeholder={props.placeholder}
            onChange={(v) => {
              props.onChange(v);
            }}
            debounce={100}
            disabled={props.disabled}
            // className="bg-light"
          />
        );
      case "date":
        return (
          <div className="row">
            <div className="col-md-12">
              <DatePicker
                className={"form-control w-100"}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={props.value ? new Date(props.value) : null}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  props.onChange(
                    date ? moment(date).format("YYYY-MM-DD") : null
                  );
                }}
                maxDate={props.maxDate}
                minDate={props.minDate}
                placeholderText={props.placeholder || ""}
                fixedHeight
                popperPlacement="bottom"
                isClearable
                // popperModifiers={[
                //   {
                //     flip: {
                //       behavior: ["bottom"], // don't allow it to flip to be above
                //     },
                //     preventOverflow: {
                //       enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                //     },
                //     hide: {
                //       enabled: true, // turn off since needs preventOverflow to be enabled
                //     },
                //   },
                // ]}
                onChangeRaw={(e) => e?.preventDefault()}
                onBlur={props.handleBlur}
              />
            </div>
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            id={props.id}
            placeholder={props.placeholder}
            value={props.value}
            required={props.isRequired ? props.isRequired : false}
            onChange={(e) => props.onChange(e.target.value)}
            disabled={props.disabled ? props.disabled : false}
            maxLength={props.maxLength || undefined}
            minLength={props.minLength || undefined}
            rows={props.rows || 4}
            onBlur={props.handleBlur}
          />
        );

      case "password":
        return (
          <input
            value={props.value}
            className="form-control"
            id={props.placeholder}
            type="password"
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
            required={props.isRequired ? props.isRequired : false}
            disabled={props.disabled ? props.disabled : false}
            maxLength={props.maxLength || undefined}
            minLength={props.minLength || undefined}
            pattern={props.pattern || undefined}
            ref={ref}
            onBlur={props.handleBlur}
          />
        );

      case "file":
        return (
          <input
            ref={ref}
            id={props.id}
            type="file"
            accept={props.accept}
            onChange={(e) => {
              props.onChange(e.target?.files ? e.target?.files[0] : "");
            }}
            onBlur={props.handleBlur}
          />
        );

      case "checkbox":
        return (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={props.id}
              checked={props.value}
              onChange={(e) => {
                props.onChange(e.target.checked);
              }}
              disabled={props.disabled || false}
              onBlur={props.handleBlur}
            />

            <label className="custom-control-label" htmlFor={props.id}>
              {props.hideLabel ? null : props.placeholder}
            </label>
          </div>
        );
    }
  };

  return (
    <div
      className={`form-group form-holder ${
        props.styleClass ? props.styleClass : ""
      }`}
    >
      {props.hideLabel || props.type === "checkbox" ? null : (
        <label
          className={`small mb-1 ${props.isRequired ? "required" : ""}`}
          htmlFor={props.placeholder}
        >
          {props.placeholder}
        </label>
      )}
      {renderInput(props)}
      {props.errorMsg && <div className="error-info">{props.errorMsg}</div>}
    </div>
  );
});

export default AdminInput;
