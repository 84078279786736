import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import API from "../../../config/api";
import request from "../../../utils/request";
import RemarksTable from "./routesTable";
import SnackBar, { SnackBarProps } from "../../../components/snackBar";
import PageHead from "../../../components/pageHead";
import { PageLoader } from "../../../components/loader";
import { ListItem, PageDetails } from "./interfaces";
import Input from "../../../components/input";
import saveAs from "file-saver";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";

const RouteAdminSalesCustomerProductwiseDetails: React.FC<any> = (
  props: any
) => {
  const location = useLocation();
  const params: any = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState<ListItem[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<SnackBarProps>({
    open: false,
  });
  const [pageDetails, setPageDetails] = useState<PageDetails>({
    page: params?.page | 1,
    limit: 0,
    total: 0,
    from: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [salesStartDate, setSalesStartDate] = useState<any>(
    location.state?.salesStartDate
      ? location.state?.salesStartDate
      : moment().format("YYYY-MM-DD")
  );
  const [salesEndDate, setSalesEndDate] = useState<any>(
    location.state?.salesEndDate
      ? location.state?.salesEndDate
      : moment().format("YYYY-MM-DD")
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const getRemarks = useCallback(
    (page: number, data: any) => {
      setLoading(true);
      let paramsData = {
        ...data,
        customer_id: params.customer_id,
        page,
      };
      request(API.ROUTE_ADMIN_SALES_CUSTOMER_PRODUCTWISE_DETAILS, "GET", {
        params: paramsData,
      })
        ?.then((response) => {
          if (response.status && response.status === 200) {
            setList(response.data.item_sale_report.data);
            setPageDetails({
              page: response.data.item_sale_report.current_page,
              limit: response.data.item_sale_report.per_page,
              total: response.data.item_sale_report.total,
              totalPages: response.data.item_sale_report.last_page,
              from: response.data.item_sale_report.from,
            });
            setLoading(false);
          } else {
            throw new Error(
              response.message ? response.message : "Error loading data"
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setSnackbarOpen({
            open: true,
            message: err.message ? err.message : "Something went wrong",
            variant: "error",
          });
        });
    },
    [params.customer_id]
  );

  useEffect(() => {
    if (location.state) {
      getRemarks(params.page, {
        delivery_start_date: location.state.salesStartDate,
        delivery_end_date: location.state.salesEndDate,
      });
    }
  }, [getRemarks, location.state, params.page]);

  const downloadReport = () => {
    setIsDownloading(true);
    let paramsData = {
      delivery_start_date: salesStartDate,
      delivery_end_date: salesEndDate,
      customer_id: params.customer_id,
    };
    request(API.ROUTE_ADMIN_SALES_CUSTOMER_PRODUCTWISE_DETAILS_PDF, "BLOB", {
      params: paramsData,
    })
      ?.then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        saveAs(
          blob,
          `Route-Sales-Cust-Delivery-${moment(
            location.state?.salesStartDate
          ).format("DD_MM")}-${moment(location.state?.salesEndDate).format(
            "DD_MM"
          )}.pdf`
        );
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
        setSnackbarOpen({
          open: true,
          message: err.message ? err.message : "Something went wrong",
          variant: "error",
        });
      });
  };

  const copyTable = () => {
    const elTable = document.querySelector("table");
    if (elTable) {
      let range, sel;

      // Ensure that range and selection are supported by the browsers
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        // unselect any element in the page
        sel?.removeAllRanges();

        try {
          range.selectNodeContents(elTable!);
          sel?.addRange(range);
        } catch (e) {
          range.selectNode(elTable!);
          sel?.addRange(range);
        }

        document.execCommand("copy");
      }

      sel?.removeAllRanges();
      alert("Content Copied");
    }
  };

  const handlePageClick = (data: any) => {
    navigate(
      `/admin/route-admin-sales-summary-details/${
        params.user_id
      }/customer-product-summary/${params.customer_id}/page/${
        data.selected + 1
      }`,
      {
        state: location.state,
      }
    );
    // getRemarks(data.selected + 1);
  };

  const debouncedChangeHandler = debounce(() => {
    navigate(
      `/admin/route-admin-sales-summary-details/${params.user_id}/customer-product-summary/${params.customer_id}/page/1`,
      {
        state: {
          salesStartDate,
          salesEndDate,
        } as any,
      }
    );
  }, 300);

  return loading ? (
    <PageLoader />
  ) : (
    <main className="norml-bd-pd-y">
      <SnackBar
        snackbar={snackbarOpen}
        onClose={() =>
          setSnackbarOpen({
            open: false,
          })
        }
      />
      <div className="container-fluid">
        <PageHead
          heading={`Customer Product Wise Sales Summary - ${
            location.state?.customer || ""
          }`}
          subhead={`Customer Product Wise Sales Summary`}
          breadcrumbs={[
            { title: "Home", link: `/admin/routes`, isActive: false },
            {
              title: "Route Admin Sales Summary Report",
              link: `/admin/route-admin-sales-summary-report`,
              isActive: false,
            },
            {
              title: `Route Admin Sales Summary Details`,
              link: `/admin/route-admin-sales-summary-details/${params.user_id}/page/1`,
              isActive: false,
            },
            {
              title: `Customer Product Wise Sales Summary`,
              link: ``,
              isActive: true,
            },
          ]}
        />
        <div className="card mb-4">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-md-12">
                <div className="row filter-holder filter-flex justify-content-start">
                  <div className="col-md-2">
                    <Input
                      id={"id"}
                      type={"date"}
                      value={salesStartDate}
                      label={"Sales Start Date"}
                      maxDate={new Date()}
                      placeholder={"Sales Start Date"}
                      onChange={(v) => setSalesStartDate(v)}
                    />
                  </div>
                  <div className="col-md-2">
                    <Input
                      id={"id"}
                      type={"date"}
                      value={salesEndDate}
                      label={"Sales End Date"}
                      placeholder={"Sales To Date"}
                      maxDate={new Date()}
                      onChange={(v) => setSalesEndDate(v)}
                    />
                  </div>
                  <div className="col-md-2 align-self-end mb-3">
                    <div>
                      <button
                        className={`btn btn-primary btn-size-sm `}
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            salesStartDate &&
                            salesEndDate &&
                            moment(salesStartDate).isAfter(salesEndDate)
                          ) {
                            setSnackbarOpen({
                              open: true,
                              message: "Start date should be before end date",
                              variant: "error",
                            });
                          } else {
                            debouncedChangeHandler();
                          }
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2 mt-3 d-flex justify-content-end">
                    <button
                      className={`btn btn-primary btn-size-sm mr-3 h-auto`}
                      // disabled={isDownloading}
                      onClick={(e) => {
                        e.preventDefault();
                        copyTable();
                      }}
                    >
                      Copy to Clipboard
                    </button>
                    <button
                      disabled={isDownloading}
                      className={`btn btn-primary btn-size-sm h-auto`}
                      onClick={(e) => {
                        e.preventDefault();
                        downloadReport();
                      }}
                    >
                      Export Pdf
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <RemarksTable
              list={list}
              pageDetails={pageDetails}
              handlePageClick={(data) => handlePageClick(data)}
              {...params}
              {...location.state}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default RouteAdminSalesCustomerProductwiseDetails;
