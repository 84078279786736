import React from "react";
import { Link } from "react-router-dom";

type Breadcrumb = {
  title: string;
  isActive: boolean;
  link?: string;
  state?: any;
};

interface PropTypes {
  heading: string;
  subhead: string;
  breadcrumbs: Breadcrumb[];
}

const Head: React.FC<PropTypes> = (props: PropTypes) => {
  return (
    <div className="normalheader">
      <div className="hpanel">
        <div className="panel-body">
          <div id="hbreadcrumb" className="pull-right m-t-lg">
            <ol className="hbreadcrumb breadcrumb">
              {props.breadcrumbs && props.breadcrumbs.length > 0
                ? props.breadcrumbs.map((item, i) => {
                    if (!item.isActive && item.link) {
                      return (
                        <li key={"bread" + i}>
                          <Link to={item.link} state={item.state}>
                            {item.title}
                          </Link>
                        </li>
                      );
                    } else {
                      return (
                        <li className="active" key={"bread" + i}>
                          <span>{item.title}</span>
                        </li>
                      );
                    }
                  })
                : null}
            </ol>
          </div>
          <div className="m-head">
            <h1 className="mt-4">{props.heading}</h1>
            <small>{props.subhead}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
