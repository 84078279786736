import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import request from "../../utils/request";
import API from "../../config/api";
import LocalStorageService from "../../utils/localStorageService";

type authState = {
  error: any;
  loading: boolean;
  profile: any;
  role: "admin" | "route_manager" | "super_admin" | undefined;
  userPermissions: any[];
  userUrl: string | null;
  managerPermissions: any;
};

const profile = localStorage.getItem("profile");
const permissions = localStorage.getItem("userPermissions");
const managerPermissions = localStorage.getItem("managerPermissions");

const initialState: authState = {
  role: localStorage.getItem("role")
    ? (localStorage.getItem("role") as
        | "admin"
        | "route_manager"
        | "super_admin"
        | undefined)
    : undefined,
  error: null,
  loading: false,
  userUrl: localStorage.getItem("userUrl")
    ? localStorage.getItem("userUrl")
    : "",
  profile: profile ? JSON.parse(profile) : null,
  userPermissions: permissions ? JSON.parse(permissions) : [],
  managerPermissions: managerPermissions ? JSON.parse(managerPermissions) : {}
};
export const login = createAsyncThunk(
  "user/login",
  async (params: any, { rejectWithValue }) => {
    try {
      let res: any = await request(API.LOGIN, "POST", params);
      if (res.status) {
        switch (res.status) {
          case 200:
            const userPermissions =
              (res.data?.user_permissions || []).map(
                (item: { permission: any }) => item.permission
              ) || [];
            const routeManagerPermissions = res.data?.managerPermissions;
            LocalStorageService.setToken({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              role: res.data.role,
              userUrl: res.data.role,
              profile: res.data.profile,
              userPermissions,
              managerPermissions: routeManagerPermissions
            });
            const result: any = {
              profile: res.data.profile,
              role: res.data.role,
              userUrl: res.data.role,
              userPermissions: userPermissions,
              managerPermissions: routeManagerPermissions
            };
            // onAuthSuccess(res.data.role === 'route_manager');
            return result;
          case 403:
          case 404:
            return rejectWithValue({
              status: res.status,
              errors: {
                username: "",
                password: res.message ? res.message : ""
              }
            });

          default:
            return rejectWithValue("Something went wrong");
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearError(state) {
      state.error = "";
    },
    logout(state) {
      state.error = "";
      state.role = undefined;
      state.loading = false;
      state.userUrl = null;
      state.profile = null;
      state.userPermissions = [];
    },
    updatePettyForRouteManager(state, action: PayloadAction<string | number>) {
      state.profile.petty_cash = (
        parseFloat(state.profile.petty_cash || "0") +
        parseFloat(action?.payload?.toString() || "0")
      ).toFixed(2);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.userPermissions = [];
    });
    builder.addCase(
      login.fulfilled,
      (
        state,
        action: PayloadAction<{
          profile: any;
          userPermissions: any[];
          role: any;
          userUrl: string;
          managerPermissions: any;
        }>
      ) => {
        state.loading = false;
        state.error = null;
        state.profile = action.payload?.profile;
        state.role = action.payload?.role;
        state.userPermissions = action.payload?.userPermissions || [];
        state.userUrl = action.payload?.userUrl;
        state.managerPermissions = action.payload?.managerPermissions || {};
      }
    );
    builder.addCase(login.rejected, (state, action) => {
      state.error = action?.payload || action.error.message || "";
      state.loading = false;
      state.role = undefined;
      state.userPermissions = [];
    });
  }
});

export const { clearError, logout, updatePettyForRouteManager } =
  authSlice.actions;
export default authSlice.reducer;
