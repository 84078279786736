import adminSidebarItems from "../layouts/admin/sidebarItems.json"

const getFirstRoute = (userPermissions: any[]) => {
  let firstRoute = "settings";
  adminSidebarItems.every((item) => {
    if (userPermissions.includes(item.permission)) {
      firstRoute = item.link;
      return false;
    }
    return true;
  });

  return firstRoute;
};

export default getFirstRoute;