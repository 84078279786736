import React from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

interface WrapperProps {
  className?: string;
  message: string;
  onClose: () => void;
  variant: "error" | "info" | "success" | "warning";
}

interface AllSnackbarProps {
  snackbar: {
    open: boolean;
    message?: string;
    variant?: "error" | "info" | "success" | "warning";
  };
  onClose: () => void;
}

const MySnackbarContentWrapper: React.FC<WrapperProps> = (props) => {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

const CustomizedSnackbars: React.FC<AllSnackbarProps> = (props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={props.snackbar.open}
      autoHideDuration={5000}
      onClose={props.onClose}
    >
      <MySnackbarContentWrapper
        onClose={props.onClose}
        variant={props.snackbar.variant ? props.snackbar.variant : "info"}
        message={props.snackbar.message ? props.snackbar.message : ""}
      />
    </Snackbar>
  );
};

export interface SnackBarProps {
  open: boolean;
  message?: string;
  variant?: "error" | "info" | "success" | "warning";
}

export default CustomizedSnackbars;
