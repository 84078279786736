import React from "react";
import { TableProps } from "./interfaces";

import nodata from "../../../assets/img/nodata.png";
import ReactPaginate from "react-paginate";

const RemarkTable: React.FC<TableProps> = (props: TableProps) => {
  return (
    <div className="row">
      {props.list && props.list.length > 0 ? (
        <div className="col-xl-9 col-md-9">
          <div className="table-responsive custom-table">
            <table
              className="table table-borderless table-striped"
              width="100%"
              cellSpacing="0"
            >
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th className="text-right">Customer</th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right">Sales Amount</th>
                </tr>
              </thead>
              <tbody>
                {props.list && props.list.length > 0
                  ? props.list.map((listItem, i) => {
                      let id =
                        props.pageDetails.from + i > 9
                          ? (props.pageDetails.from + i).toString()
                          : (props.pageDetails.from + i)
                              .toString()
                              .padStart(2, "0");
                      return (
                        <tr key={"user" + id}>
                          <td>{id}</td>
                          <td className="text-right">
                            {listItem.product_name}
                          </td>
                          <td className="text-right">
                            {listItem.sales_quantity}
                          </td>
                          <td className="text-right">
                            {listItem.sales_amount}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          <div className="c-pagination flex-end">
            {props.pageDetails.totalPages > 1 ? (
              <ReactPaginate
                initialPage={props.pageDetails.page - 1}
                forcePage={props.pageDetails.page - 1}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                pageCount={props.pageDetails.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(data: any) => props.handlePageClick(data)}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disableInitialCallback={true}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="col-xl-12 col-md-12">
          <div className="no-data-holder">
            <img src={nodata} alt="grameenmilk" />
            <p>No Details Found</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemarkTable;
