/* eslint-disable no-script-url */
import React from "react";
import { useDispatch } from "react-redux";

import LocalStorageService from "../../utils/localStorageService";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/img/logo-1.png";
import { logout } from "../../store/slices/auth";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleLogout = (e: any) => {
    document.getElementById("admin-logout-dropdown")?.classList.remove("show");
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-light bg-white c__nav">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="grameenmilk" />
      </Link>
      <button
        className="btn btn-link btn-sm order-1 order-lg-0 hmr_btn"
        id="sidebarToggle"
        onClick={(e) => {
          e.preventDefault();
          document.body.classList.toggle("sb-sidenav-toggled");
        }}
      >
        <i className="fa fa-bars"></i>
      </button>

      <ul className="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <li className="nav-item nan-link-profile dropdown">
          <button
            className="btn btn-link nav-link dropdown-toggle"
            id="userDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(e) => {
              e.preventDefault();
              document
                .getElementById("admin-logout-dropdown")
                ?.classList.toggle("show");
              document.body.addEventListener("click", toggleLogout, {
                capture: true,
                once: true,
              });
            }}
          >
            <img src={logo} className="rounded-circle" alt="pre-poll" />
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            id="admin-logout-dropdown"
            aria-labelledby="userDropdown"
          >
            <Link className="dropdown-item" to={`/admin/settings`}>
              Change Password
            </Link>
            <div className="dropdown-divider"></div>
            <button
              className="btn btn-link dropdown-item"
              onClick={(e) => {
                e.preventDefault();
                dispatch(logout());
                LocalStorageService.clearToken();
                navigate("/login");
              }}
            >
              Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
