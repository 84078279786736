import service from "./service";

const ApiConnect = (url: string, method: string, params: any = {}) => {
  let encodedURL = url;

  switch (method) {
    case "GET":
      return service
        .get(encodedURL, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "POST":
      return service
        .post(encodedURL, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "PUT":
      return service
        .put(encodedURL, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "DELETE":
      return service
        .delete(encodedURL, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "PATCH":
      return service
        .patch(encodedURL, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "BLOB":
      return service
        .get(encodedURL, { ...params, responseType: "arraybuffer" })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });

    case "POST-BLOB":
      return service
        .post(encodedURL, params, { responseType: "arraybuffer" })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response && error.response.data
            ? error.response.data
            : error;
        });
    default:
      return;
  }
};

export default ApiConnect;
