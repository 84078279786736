/* eslint-disable no-script-url */
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "../../components/header";
import Footer from "../../components/footer";
import SideNavItems from "./sidebarItems.json";
import RouteManagerSidenav from "../../components/sidenav/routeManagerSidenav";
import { RootState } from "../../store/store";

const RouteManagerLayout: React.FC = (props: any) => {
  const { profile, role } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("sb-nav-fixed");
    return () => {
      document.body.classList.remove("sb-nav-fixed");
    };
  }, []);

  if (role !== "route_manager") {
    return <Navigate to={"/login"} />;
  }

  return (
    <React.Fragment>
      <Header />
      <div id="layoutSidenav">
        <RouteManagerSidenav
          items={SideNavItems as any}
          user={profile}
          pathname={location.pathname}
        />
        <div id="layoutSidenav_content">
          <Outlet />
          {/* <Switch>
            <Route
              exact
              path={"/route-manager/routes/view/:id"}
              component={RouteView}
            />
            <Route
              exact
              path={["/route-manager/routes", "/route-manager/routes/:page"]}
              component={Routes}
            />
            <Route
              exact
              path={[
                "/route-manager/customers",
                "/route-manager/customers/:page",
              ]}
              component={UsersList}
            />
            <Route
              exact
              path={"/route-manager/customers/view/:id"}
              component={Customer}
            />
            <Route
              exact
              path={[
                "/route-manager/customers-report",
                "/route-manager/customers-report/:page",
                "/route-manager/routes/:id/customers-report",
                "/route-manager/routes/:id/customers-report/:page",
              ]}
              component={CustomersReport}
            />
            <Route
              exact
              path={[
                "/route-manager/collection-report",
                "/route-manager/collection-report/:page",
                "/route-manager/routes/:id/collection-report",
                "/route-manager/routes/:id/collection-report/:page",
              ]}
              component={CollectionReport}
            />
            <Route
              exact
              path={
                "/route-manager/routes/:id/customers-sales-report/:customerId"
              }
              component={CustomersSalesReport}
            />
            <Route
              exact
              path={"/route-manager/route-managers/view/:id"}
              component={RouteAdminDetails}
            />
            <Route
              exact
              path={[
                "/route-manager/route-managers",
                "/route-manager/route-managers/:page",
              ]}
              component={RouteAdmins}
            />
            <Route exact path="/route-manager/settings" component={Settings} />
          </Switch> */}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RouteManagerLayout;
