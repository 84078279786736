import React, { useEffect } from "react";

const Loader: React.FC = () => {
  useEffect(() => {
    document.body.classList.add("loader-fixed");
    document.getElementById("root")?.classList.add("h-100");
    return () => {
      document.body.classList.remove("loader-fixed");
      document.getElementById("root")?.classList.remove("h-100");
    };
  }, []);
  return (
    <div className="bodyloader">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const PageLoader: React.FC = () => {
  return (
    <div className="bodyloader">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const FilterLoader: React.FC = () => {
  return <div className="filterLoader"></div>;
};

export default Loader;
