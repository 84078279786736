const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.accessToken);
    tokenObj.refreshToken &&
      localStorage.setItem("refresh_token", tokenObj.refreshToken);
    tokenObj.role && localStorage.setItem("role", tokenObj.role);
    tokenObj.userUrl && localStorage.setItem("userUrl", tokenObj.userUrl);
    tokenObj.profile &&
      localStorage.setItem("profile", JSON.stringify(tokenObj.profile));
    tokenObj.userPermissions &&
      localStorage.setItem(
        "userPermissions",
        JSON.stringify(tokenObj.userPermissions)
      );
  }
  function _setAccessToken(accessToken) {
    localStorage.setItem("access_token", accessToken);
  }
  function _setRefreshToken(accessToken) {
    localStorage.setItem("refresh_token", accessToken);
  }
  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    localStorage.removeItem("userUrl");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setAccessToken: _setAccessToken,
    setRefreshToken: _setRefreshToken,
  };
})();
export default LocalStorageService;
