/* eslint-disable no-script-url */
import React from "react";
import { Link } from "react-router-dom";

interface SideNavItem {
  link: string;
  icon: string;
  label: string;
  enabled: boolean;
  permission: string;
}

interface SideNavProps {
  items: SideNavItem[];
  user: any;
  pathname: string;
}

const RouteManagerSidenav: React.FC<SideNavProps> = ({
  items,
  user,
  pathname,
}) => {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-light"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-header">
          <div className="small">Logged in as:</div>
          {user.name}
        </div>
        <div className="sb-sidenav-header bg-danger">
          <div className="small">Petty Cash:</div>
          {user.petty_cash}
        </div>
        <div className="sb-sidenav-menu">
          <div className="nav">
            {items && items.length > 0
              ? items.map((item: SideNavItem, i: number) => {
                  if (item.enabled) {
                    return (
                      <Link
                        className={`nav-link ${
                          pathname.split("/")[2] === item.link
                            ? "nav-link-active"
                            : ""
                        }`}
                        to={`/route-manager/${item.link}`}
                        key={"sidenav" + i}
                      >
                        <div className="sb-nav-link-icon">
                          <i
                            className={`fa ${item.icon}`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {item.label}
                      </Link>
                    );
                  } else {
                    return (
                      <div
                        className={`nav-link sidenav-disabled`}
                        key={"sidenav" + i}
                      >
                        <div className="sb-nav-link-icon">
                          <i
                            className={`fa ${item.icon}`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {item.label}
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default RouteManagerSidenav;
